import RichText from 'components/RichText';
import classNames from 'classnames';
import Media from 'components/Media';
import Link from 'components/Link';
import useModal from 'lib/hooks/useModal';
import Modal from 'components/Modal';
import Image from 'components/Media/elements/Image';
import Icon from 'components/Icon';
import VideoModalContent from 'components/Media/elements/VideoModalContent';

const Tile = ({ theme, title, description, media, variant, type }) => {
    const { isOpen, setIsOpen } = useModal();
    return (
        <>
            {(media.type === 'image_link' || media.type === 'pdf') && media && (
                <Link
                    href={media.button?.cta_link}
                    className={classNames('tile', {
                        [`bg-${theme}`]: theme,
                        [`tile--${variant}`]: variant,
                    })}
                    target={media.button.target}
                >
                    {media && (
                        <Media
                            wrapperClassName={'tile__media'}
                            wrapperIconClassName={'tile__icon'}
                            media={media.media}
                            layout={'fill'}
                            objectFit={'contain'}
                            objectPosition={'center'}
                            disableCaption
                            disableElevation
                        />
                    )}

                    <div className="tile__content">
                        {title && (
                            <RichText className="tile__title" data={title} />
                        )}
                        {description && (
                            <RichText
                                className="tile__description"
                                data={description}
                            />
                        )}
                    </div>
                </Link>
            )}
            {type === 'filter' && (
                <button
                    className={classNames('tile', {
                        [`bg-${theme}`]: theme,
                        [`tile--${variant}`]: variant,
                    })}
                >
                    {media && (
                        <Image
                            layout={'fill'}
                            objectFit={'cover'}
                            objectPosition={'center'}
                            wrapperClassName={'tile__media'}
                            image={media}
                            disableCaption
                            disableElevation
                        />
                    )}
                    {media?.icon && (
                        <Icon
                            wrapperIconClassName={'tile__icon'}
                            icon={media.icon}
                            color={media.color}
                            primary={media.primary}
                            secondary={media.secondary}
                        />
                    )}
                    <div className="tile__content">
                        {title?.value && (
                            <RichText className="tile__title" data={title} />
                        )}
                        {description?.value && (
                            <RichText
                                className="tile__description"
                                data={description}
                            />
                        )}
                    </div>
                </button>
            )}
            {media.type === 'video' && media && (
                <>
                    <button
                        onClick={() => setIsOpen(true)}
                        className={classNames('tile', {
                            [`bg-${theme}`]: theme,
                            [`tile--${variant}`]: variant,
                        })}
                    >
                        {media && media.thumbnail && (
                            <Image
                                layout={'fill'}
                                objectFit={'contain'}
                                objectPosition={'center'}
                                wrapperClassName={'tile__media'}
                                image={media.thumbnail}
                                disableCaption
                                disableElevation
                            />
                        )}
                        {media && media.icon && (
                            <Icon
                                wrapperIconClassName={'tile__icon'}
                                icon={media.icon}
                                color={media.color}
                                primary={media.primary}
                                secondary={media.secondary}
                            />
                        )}
                        <div className="tile__content">
                            {title && (
                                <RichText
                                    className="tile__title"
                                    data={title}
                                />
                            )}
                            {description && (
                                <RichText
                                    className="tile__description"
                                    data={description}
                                />
                            )}
                        </div>
                    </button>
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                        <VideoModalContent
                            video={media}
                            disableModal
                            setIsOpen={setIsOpen}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

export default Tile;
