import Introduction from 'components/Introduction';
import RichText from 'components/RichText';
import { Fragment } from 'react';
import classNames from 'classnames';
import Address from '../elements/Address';
import Phone from '../elements/Phone';
import Email from '../elements/Email';

const Contact1a = ({ settings, introduction, contacts, meta }) => {
	return (
		<section
			className={classNames('section last-child-border-bottom', {
				...settings
			})}
			{...meta}
		>
			<div className='container'>
				<Introduction {...introduction} />
				{contacts && (
					<>
						{contacts.map((item, index) => {
							const { title, description, communications } = item;
							return (
								<div className='grid-md-12 contact' key={index}>
									<div className='col-start-md-1 col-end-md-5'>
										{title && <h3>{title}</h3>}
										{description?.value && <RichText data={description} />}
									</div>
									{communications && <div className='col-start-md-6 col-end-md-13'>
										<div className='grid-md-2'>
											{communications?.map((item, index) => {
												const { type } = item;
												return (
													<Fragment key={index}>
														{type === 'phone' && <Phone data={item} />}
														{type === 'email' && <Email data={item} />}
														{type === 'address' && <Address data={item} />}
													</Fragment>
												);
											})}
										</div>
									</div>}
								</div>
							);
						})}
					</>
				)}
			</div>
		</section>
	);
};

export default Contact1a;
