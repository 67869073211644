/**
 * Contact Block
 * A Contact Information block is a way to display phone numbers, emails, or addresses by category or group. This block is not a form.
 */

import Contact1a from './variants/Contact1a';

import Contact2a from './variants/Contact2a';
import Contact2b from './variants/Contact2b';

const prefix = 'contact';

const Contact = ({ ...block }) => {
   switch (block.variant) {
      case `${prefix}_1a`:
         return <Contact1a {...block} />;
      case `${prefix}_2a`:
         return <Contact2a {...block} />;
      case `${prefix}_2b`:
         return <Contact2b {...block} />;
      default:
         return null;
   }
};

export default Contact;
