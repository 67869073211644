import Introduction from 'components/Introduction';
import RichText from 'components/RichText';
import { Fragment } from 'react';
import classNames from 'classnames';
import Address from '../elements/Address';
import Phone from '../elements/Phone';
import Email from '../elements/Email';

const Contact2a = ({ settings, introduction, contacts, meta }) => {
	return (
		<section
			className={classNames('section last-child-border-bottom', {
				...settings
			})}
			{...meta}
		>
			<div className='container'>
				<Introduction {...introduction} size={'sm'} />
				{contacts && (
					<>
						{contacts.map((item, index) => {
							const { title, description, communications } = item;
							return (
								<div className='contact' key={index}>
									{title && <h3>{title}</h3>}
									{description?.value && <RichText className={classNames({
												'mb-8':communications
											})} data={description} />}
									{communications && <div className='grid-md-3'>
										{communications?.map((item, index) => {
											const { type } = item;
											return (
												<Fragment key={index}>
													{type === 'phone' && <Phone data={item} />}
													{type === 'email' && <Email data={item} />}
													{type === 'address' && <Address data={item} />}
												</Fragment>
											);
										})}
									</div>}
								</div>
							);
						})}
					</>
				)}
			</div>
		</section>
	);
};

export default Contact2a;
